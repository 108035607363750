import axios from 'axios';

export const isBrowser = () => typeof window !== "undefined"

export const getUser = () =>
  isBrowser() && window.localStorage.getItem("userGranjaTec")
    ? JSON.parse(window.localStorage.getItem("userGranjaTec"))
    : null

const setUser = user => window.localStorage.setItem("userGranjaTec", JSON.stringify(user))

export const handleLogin = (_username, _password ,callback) => {
    const headers= {
      'Content-Type': 'application/json'
    }
    axios.post("/signin/", { "username":_username,"password":_password },{
        headers: headers
        // aquest es el loading overlay que s'ha de renderitzar
        // <div class="overlay-loading"></div>
    }).then(res => {
      console.log(JSON.stringify(res.data))
        if (res.data.errorCode) {
          return callback(res.data.errorMessage);
        }
        console.log("user= "+JSON.stringify(res.data))
        setUser({
            token : res.headers["x-auth-token"],
            nom: res.data.nom,
            id: res.data.id,
            nivell : res.data.nivell,          
            idEmpresa:res.data.company.idEmpresa,
            company: res.data.company.nom,
            especific: res.data.especific
        })
        return callback(null, res)
      }).catch(error => {
        return callback(error.message);        
      })
  return false
}

export const isLoggedIn = () => {
  const user = getUser()
  if (user) return true; else return false;
  
}

export const logout = () => {
  const headers= {
    'Content-Type': 'application/json'
  }
  let user=JSON.parse(window.localStorage.getItem("userGranjaTec"))
  axios.defaults.headers.common['Authorization'] =  user.token
  axios.post("/auth/logout", {},{
     headers: headers
  }).then(res => {
    window.localStorage.removeItem("userGranjaTec");
    setUser(null)
    return;
  }) 

 
}